// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-worksheet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.work-loc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-worksheet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

@media print {
  .page {
    page-break-after: always; /* Ensures that each page breaks after 13 items */
  }

  .print-item {
    page-break-inside: avoid;
    break-inside: avoid; /* Prevent items from breaking across pages */
    margin-bottom: 10px; /* Optional, for some spacing */
  }

  .page table td,
  .page table th {
    font-size: 10px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/BloodTesting/export/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE;IACE,wBAAwB,EAAE,iDAAiD;EAC7E;;EAEA;IACE,wBAAmB;IAAnB,mBAAmB,EAAE,6CAA6C;IAClE,mBAAmB,EAAE,+BAA+B;EACtD;;EAEA;;IAEE,0BAA0B;EAC5B;AACF","sourcesContent":[".header-worksheet {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.work-loc {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.footer-worksheet {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  padding: 0 1rem;\r\n}\r\n\r\n@media print {\r\n  .page {\r\n    page-break-after: always; /* Ensures that each page breaks after 13 items */\r\n  }\r\n\r\n  .print-item {\r\n    break-inside: avoid; /* Prevent items from breaking across pages */\r\n    margin-bottom: 10px; /* Optional, for some spacing */\r\n  }\r\n\r\n  .page table td,\r\n  .page table th {\r\n    font-size: 10px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
