import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import {
  fetchInventoryData,
  deleteInventory,
  fetchInventoryByStatus,
} from "../../../utils/queries/production/inventoryQuery";
import { DeleteModal, Modal } from "../../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import { BloodBagLabelling } from "../../Labelling";
import { SearchDonation } from "../../BloodCollection";
import NewInventory from "./NewInventory";
import {
  ROLE_COMPONENT_SEPARATION,
  ROLE_INVENTORY,
} from "../../../constants/ROLES";
import exportPDF from "../../../utils/exportPDF";
import CardCount from "../../CardCount/CardCount";
import { CentersFilter, DateFilter } from "../../Filters";
import { CSVLink } from "react-csv";
import moment from "moment";

let copyData = [];

class Inventories extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
    counts: {
      donations: 0,
      quarantined: 0,
      active: 0,
      rejected: 0,
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.getData(true);
    }
  }

  fetchData(isLoading) {
    this.getData(isLoading);
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  returnFilters = async (search) => {
    const { page, limit } = this.state;
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();
    const { filters } = this.props;
    return {
      ...filters,
      page,
      limit,
      donation: this.props.donationId,
      id: this.props.inventoryId,
      center: centerRole,
      centerSite: centerSiteRole,
      ...search,
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      const { data, count } = await fetchInventoryByStatus(
        defaultLanguage,
        filters
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/production/center/${item?.center?._id}/${item?.center?.name}/${item?.centerSite?._id}/${item?.centerSite?.name}`),
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].total_collected,
        key: "incoming",
      },
      {
        title: language[this.props.defaultLanguage].total_received,
        key: "received",
      },
      {
        title: language[this.props.defaultLanguage].total_processed,
        key: "processed",
      },
      {
        title: language[this.props.defaultLanguage].total_rejected,
        key: "rejected",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteInventory(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const filters = await this.returnFilters();

      const { data } = await fetchInventoryByStatus(defaultLanguage, filters);

      this.setState({
        isLoading: false,
        csvData: data.map((el) => {
          return {
            ...el,
            center: el?.center?.name,
            centerSite: el?.centerSite?.name,
          };
        }),
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    let headers = [
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].total_collected,
        key: "incoming",
      },
      {
        title: language[this.props.defaultLanguage].total_received,
        key: "received",
      },
      {
        title: language[this.props.defaultLanguage].total_processed,
        key: "processed",
      },
      {
        title: language[this.props.defaultLanguage].total_rejected,
        key: "rejected",
      },
    ];

    const { defaultLanguage } = this.props;

    const filters = await this.returnFilters();

    const { data } = await fetchInventoryByStatus(defaultLanguage, filters);

    this.setState({
      isLoading: false,
    });

    exportPDF("Inventories", headers, data);
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });
    await this.getData(true, {
      center: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e.target.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <>
        {this.props.showCounts && (
          <div className="row">
            <div style={{ marginBottom: 16 }}>
              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                <CentersFilter />
                <DateFilter />
              </div>
            </div>
            <div className="col-md-3">
              <CardCount
                count={this.state.counts.active}
                title={language[this.props.defaultLanguage].active}
                color="#078ECE"
                icon="check"
                isLoading={this.state.isLoadingCounts}
                onPress={() =>
                  (window.location.href =
                    "/dashboard/production/inventory/byStatus/active")
                }
              />
            </div>
            <div className="col-md-3">
              <CardCount
                count={this.state.counts.quarantined}
                title={language[this.props.defaultLanguage].quarantined}
                color="orange"
                icon="schedule"
                isLoading={this.state.isLoadingCounts}
                onPress={() =>
                  (window.location.href =
                    "/dashboard/production/inventory/byStatus/quarantined")
                }
              />
            </div>
            <div className="col-md-3">
              <CardCount
                count={this.state.counts.rejected}
                title={language[this.props.defaultLanguage].rejected}
                color="red"
                icon="block"
                isLoading={this.state.isLoadingCounts}
                onPress={() =>
                  (window.location.href =
                    "/dashboard/production/inventory/byStatus/rejected")
                }
              />
            </div>
            <div className="col-md-3">
              <CardCount
                count={this.state.counts.donations}
                title={language[this.props.defaultLanguage].dispatched}
                color="green"
                icon="deployed_code"
                isLoading={this.state.isLoadingCounts}
                onPress={() =>
                  (window.location.href =
                    "/dashboard/production/inventory/byStatus/dispatched")
                }
              />
            </div>
          </div>
        )}

        {this.props.isReception && this.state.data.length === 1 ? (
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <NewInventory
                    getData={this.getData.bind(this)}
                    {...this.state.data[0]}
                    donationId={
                      this.props.donationId ||
                      this.state?.selectedData?.donation?._id
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Table
            data={this.state.data}
            isSearch
            page={this.state.page}
            limit={this.state.limit}
            addSearchButton
            search_text={this.state.search_text}
            searching={this.state.searching}
            handleSearch={(e) => this.onChangeText("search_text", e)}
            handlePressSearch={() =>
              this.handleRemoteSearch(this.state.search_text)
            }
            totalPageCount={this.state.totalPageCount}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            filters={[
              {
                type: "button",
                title: language[this.props.defaultLanguage].refresh,
                button_type: "button",
                icon: icons.refresh,
                onPress: (item) => this.getData(true),
              },
              {
                type: "dropdown",
                filterName: "date_range",
                title: language[this.props.defaultLanguage].date_range,
                button_type: "dropdown",
                handleApplyFilters: () => this.getData(true),
                startDate: moment().startOf("D").format("YYYY-MM-DD"),
                endDate: moment().endOf("D").format("YYYY-MM-DD"),
              },
              {
                type: "export",
                title: language[this.props.defaultLanguage].export_,
                button_type: "dropdown",
                icon: "bx bxs-download",
                options: [
                  {
                    name: "PDF",
                    onPress: this.downloadPDF.bind(this),
                  },
                  {
                    name: "CSV",
                    onPress: this.downloadExcel.bind(this),
                  },
                ],
              },
            ]}
          />
        )}
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <NewInventory
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donationId={
              this.props.donationId || this.state?.selectedData?.donation?._id
            }
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSearchModal")}
          show={this.state.showSearchModal}
          title={this.state.modalTitle}
        >
          <SearchDonation
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSearchModal"
            )}
            handleNext={(item) =>
              this.handleShowModal("showModal", item._id, { donation: item })
            }
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showBarcodeModal")}
          show={this.state.showBarcodeModal}
          title={this.state.selectedData?.donor?.donorNumber}
        >
          {this.state.selectedData &&
            this.state.selectedData.isBarcode &&
            this.state.selectedData.donation && (
              <BloodBagLabelling
                {...this.state.selectedData}
                donationId={this.state.selectedData.donation?._id}
              />
            )}
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData._id}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Inventories" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Inventories);
