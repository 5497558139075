import React, { createRef } from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Loading } from "../../../Loader";
import { Empty } from "../../../Empty";
import { fetchInventories } from "../../../../utils/queries/inventories/center";
import toastMessage from "../../../../utils/toastMessage";
import moment from "moment";
import { PrintComponent } from "../../../Print";
import Barcode from "react-barcode";
import { Checkbox, Input } from "../../../Input";
import { Button } from "../../../Button";
import axios from "axios";
import { getStorage } from "../../../../utils/storage";
import { onGenerateLabel } from "../utils";
import BarcodeScannerInput from "../../../Barcode/BarcodeScannerInput";
import rbcLogo from "../../../../assets/rbc-logo.png";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class PrintableLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      donation: {},
    };

    this.scannerInputRef = createRef();
  }

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productId !== this.props.productId) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      this.setState({ isLoading });

      const data = await fetchInventories(this.props.defaultLanguage, {
        id: this.props.productId,
      });

      this.setState({ data, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage(error);
    }
  };

  renderInfo = ({
    title,
    description,
    barCode,
    isBlocked,
    type,
    displayValue = true,
    reason,
    isTestResult,
  }) => {
    return (
      <div className="d-flex align-items-center" style={{ minHeight: ".5rem" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 12, marginRight: 8 }}>{title}</span>
          {isBlocked && <span className="text-danger">(Blocked)</span>}
          {type === "bloodGroup" && (
            <>
              <span
                style={{
                  fontSize: "24px",
                  marginLeft: "1rem",
                  fontWeight: 600,
                  marginRight: 8,
                }}
                className={!isTestResult ? "noPrint" : ""}
              >
                {barCode}{" "}
              </span>
              {reason && (
                <span className="text-danger noPrint"> {`(${reason})`}</span>
              )}
            </>
          )}
        </div>
        {description && (
          <div>
            <span style={{ fontSize: 11 }} className="text-bold">
              {description}
            </span>
          </div>
        )}
        {barCode && (
          <div>
            <Barcode
              displayValue={displayValue}
              value={barCode}
              width={2}
              height={20}
            />
          </div>
        )}
      </div>
    );
  };

  handleCheck(field) {
    this.setState({
      [field]: !this.state[field],
    });
  }

  handleUpdate = async (item) => {
    try {
      this.setState({ isUpdating: true, donation: item.donation });

      await this.updateProductInfo();

      this.getData(true);

      this.setState({ isUpdating: false });
    } catch (error) {
      this.setState({ isUpdating: false });

      toastMessage("error", error);
    }
  };

  updateProductInfo = async () => {
    const { data } = this.state;

    for (let item of data) {
      this.setState({ donation: item.donation });

      await onGenerateLabel(
        this,
        {
          ...item,
          status:
            this.state.checkDonation &&
            this.state.checkComponent &&
            this.state.checkBloodGroup
              ? "completed"
              : undefined,
          id: item._id,
          checkBloodGroup: this.state.checkBloodGroup,
          checkComponent: this.state.checkComponent,
          checkDonation: this.state.checkDonation,
        },
        this.props.type,
        "PUT",
        item._id
      );
    }
  };

  handleScan(value) {
    console.log("scanned", value);
  }

  handlePrint = async () => {
    try {
      if (!this.state.data?.length === 0)
        return toastMessage(
          "error",
          language[this.props.defaultLanguage].no_data
        );

      if (!this.state.data[0].isTestResult) return;

      this.setState({ isPrinting: true });

      const user = await getStorage();

      const options = {
        url: `${API_URL}/inventory`,
        method: "PUT",
        data: {
          status: "labelled",
          id: this.state.data[0]._id,
          isLabeled: true,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await axios(options);

      this.setState({ isPrinting: false });

      this.getData(true);
    } catch (error) {
      this.setState({ isPrinting: false });
      console.log(error);

      toastMessage("error", error);
    }
  };

  renderLabelContent = () => {
    return (
      <div className="card" style={{ paddingRight: ".5rem" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={rbcLogo}
            style={{
              aspectRatio: 2 / 2,
              objectFit: "contain",
              width: "30%",
              opacity: 0.4,
              // transform: "rotate(-30deg)",
            }}
          />
        </div>
        <div style={{ zIndex: 100 }}>
          {this.state.data.map((el, i) => {
            return (
              <div
                key={i}
                style={{
                  height: "106.8mm",
                }}
              >
                <div
                  style={{
                    // paddingTop: "1rem",
                    display: "flex",
                    // alignItems: "flex-start",
                    paddingRight: "1.8rem",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <div>
                    <span className="text-bold">BTD RWANDA KIGALI</span>
                    {/* {el?.center && (
                      <>
                        <br />
                        <span className="text-bold">{el?.center?.name}</span>
                      </>
                    )} */}
                  </div>
                </div>
                <div className="card-body">
                  {this.renderInfo({ title: el.name, barCode: el.code })}
                  {this.renderInfo({
                    title: "Volume",
                    description: el.volume + " ML",
                  })}
                  {this.renderInfo({
                    title: "Phlebotomy Date",
                    description: moment(el?.donation?.createdAt).format("lll"),
                  })}
                  {this.renderInfo({
                    title: "Expiry Date",
                    description: moment(el.expiredDate).format("lll"),
                  })}
                  {this.renderInfo({
                    title: "Donation Number",
                    barCode: el.donation?.donationNumber,
                    isBlocked: !el?.donation?.isActive,
                  })}
                  {this.renderInfo({
                    title: "Blood Group",
                    barCode: !el?.donation?.isActive ? "N/A" : el.bloodGroup,
                    type: "bloodGroup",
                    displayValue: false,
                    reason: el?.donation?.donor?.blockInfo?.blockReason,
                    isTestResult: el.isTestResult,
                  })}
                  {this.renderInfo({
                    title: "Storage Temperature",
                    description: el?.component?.storageTemperature?.name,
                  })}
                  {this.renderInfo({
                    title: "",
                    description: el.serologyResult,
                  })}
                  {this.renderInfo({
                    title: "",
                    description: el.bloodGroupingResult,
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  handleScanner(e) {
    console.log("I am here sc", e);
  }

  render() {
    if (this.state.isLoading) {
      return (
        <center>
          <Loading className="fullscreen" />
        </center>
      );
    }

    if (this.state.data.length === 0) {
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    }

    return (
      <div style={{ paddingTop: "1rem" }}>
        {/* {this.state.data?.length > 0 && this.state.data[0].isLabeled ? (
          <div>
            {
              <center>
                <div
                  className="badge badge-success badge-lg"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "center",
                    height: 40,
                    justifyContent: "center",
                  }}
                >
                  Labelled
                </div>
              </center>
            }

            {this.renderLabelContent()}
          </div>
        ) : (

        )} */}

        <PrintComponent
          handlePrint={this.handlePrint.bind(this)}
          isPrinting={this.state.isPrinting}
          buttonText={
            this.state.data?.length > 0 && this.state.data[0].isLabeled
              ? "Reprint Label"
              : "Print Label"
          }
        >
          {this.state.data?.length > 0 && this.state.data[0].isLabeled && (
            <center className="noPrint">
              <div
                className="badge badge-success badge-lg"
                style={{
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                  height: 30,
                  justifyContent: "center",
                }}
              >
                Labelled
              </div>
            </center>
          )}
          {this.renderLabelContent()}
        </PrintComponent>

        {this.state.data.map((el, i) => {
          return (
            <div key={i}>
              <div className="card-body">
                {[
                  {
                    name: "Check donation number",
                    type: "checkDonation",
                  },
                  {
                    name: " Check component type",
                    type: "checkComponent",
                  },
                  {
                    name: "Check blood group",
                    type: "checkBloodGroup",
                  },
                ].map((checkEl, i) => {
                  return (
                    <Checkbox
                      key={i}
                      label={checkEl.name}
                      onCheck={() => this.handleCheck(checkEl.type)}
                      checked={this.state[checkEl.type] || el[checkEl.type]}
                    />
                  );
                })}
              </div>
              <div className="modal-footer">
                <Button
                  text={language[this.props.defaultLanguage].cancel}
                  onPress={this.props.handleCloseModal}
                  className="btn-default"
                />
                <Button
                  text={language[this.props.defaultLanguage].update}
                  isSubmitting={this.state.isUpdating}
                  onPress={this.handleUpdate.bind(this, el)}
                />
              </div>
            </div>
          );
        })}
        {/* <Input
          ref={this.scannerInputRef}
          onChangeText={(e) => this.handleScanner(e)}
        /> */}
        <BarcodeScannerInput
          onScan={(txt) => this.handleScan(txt)}
          targetInputRef={this.scannerInputRef.current}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PrintableLabel);
