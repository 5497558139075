import React from "react";
import { connect } from "react-redux";
import { Input } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import { fetchComponentSeparationData } from "../../../utils/queries/production/componentSeparationQuery";
import { fetchComponentTransformationData } from "../../../utils/queries/production/componentTransformationQuery";
import { fetchBloodTestHistory } from "../../../utils/queries/bloodTestHistory";
import bloodTestQuery from "../../../utils/queries/bloodTest/bloodTestQuery";
import { Loading } from "../../Loader";
import { Modal } from "../../Modal";
import PrintableLabel from "./printableLabel";
import { fetchInventories } from "../../../utils/queries/inventories/center";
import { onGenerateLabel } from "./utils";

class NewLabel extends React.Component {
  state = {
    code: "",
    error: {},
    isLoading: false,
    separation: [],
    transformation: [],
    donation: {},
    serology: {},
    bloodGrouping: {},
    loadingMessage: "",
    isGenerated: false,
    selectedItem: {},
  };

  componentDidMount = async () => {
    this.getDonation(true);
    this.getComponentSeparations(true);
    this.getComponentTransformation(true);
  };

  getDonation = async () => {
    try {
      if (!this.props.donationId)
        return toastMessage("error", "Please select a donation");

      this.setState({ isLoading: true, loadingMessage: "Loading donation..." });

      const donation = await bloodDonationQuery(this.props.defaultLanguage, {
        id: this.props.donationId,
      });

      if (donation.length > 0) {
        this.getBloodTesting(donation[0]._id);
        this.setState({
          donation: donation[0],
          loadingMessage: "Donation completed",
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      toastMessage("error", error);
      this.setState({
        isLoading: false,
        loadingMessage: "Donation failed",
      });
    }
  };

  getBloodTesting = async (donation) => {
    try {
      this.setState({
        isLoading: true,
        loadingMessage: "Loading blood testing...",
      });
      const bloodTest = await bloodTestQuery(this.props.defaultLanguage, {
        donation,
      });

      let { bloodGrouping, serology } = this.state;

      if (bloodTest.length > 0) {
        const data = await fetchBloodTestHistory(this.props.defaultLanguage, {
          bloodTest: bloodTest[0]._id,
        });

        data?.forEach((el) => {
          if (el.questionnaireType === "bloodGrouping") {
            this.setState({
              bloodGrouping: el,
            });
          }

          if (el.questionnaireType === "serology") {
            this.setState({
              serology: el,
            });
          }
        });

        console.log("testserology", serology);
        console.log("bloodGrouping", bloodGrouping);

        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      toastMessage("error", error);
    }
  };

  getComponentSeparations = async () => {
    try {
      if (!this.props.donationId)
        return toastMessage("error", "Please select a donation");

      this.setState({
        isLoading: true,
        loadingMessage: "Loading component separation...",
      });

      const data = await fetchComponentSeparationData(
        this.props.defaultLanguage,
        {
          donation: this.props.donationId,
        }
      );

      if (data.length > 0) {
        this.setState({
          separation: data,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      toastMessage("error", error);
      this.setState({
        isLoading: false,
      });
    }
  };

  getComponentTransformation = async () => {
    try {
      if (!this.props.donationId)
        return toastMessage("error", "Please select a donation");

      this.setState({
        isLoading: true,
        loadingMessage: "Loading component transformation...",
      });

      const data = await fetchComponentTransformationData(
        this.props.defaultLanguage,
        {
          donation: this.props.donationId,
        }
      );

      if (data.length > 0) {
        this.setState({
          transformation: data,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      toastMessage("error", error);
      this.setState({
        isLoading: false,
      });
    }
  };

  validateForm = () => {
    let { error, donation } = this.state;

    if (!donation?._id) {
      error.donation = "Donation is required";
    }

    this.setState({ error });
  };

  handleTestResult(res = [], type) {
    let textRes = "";
    res.forEach((el) => {
      if (el.printingEnabled && el.answer) {
        if (el.answer.value && el.settings && el.settings[el.answer.value]) {
          textRes +=
            (el.settings[el.answer.value]?.abbreviation || "") +
            ":" +
            el.answer.value +
            " ";
        } else if (el.answer.value) {
          textRes += el.questionText + ":" + el.answer.value + " ";
        } else if (typeof el.answer === "string") {
          textRes += el.questionText + ":" + el.answer + " ";
        }
      }
    });

    return textRes.trim() || `Missing ${type} test result`;
  }

  onGenerate = async (item, type) => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        ["isSubmitting_" + item.code]: true,
      });

      let { donation, bloodGrouping, serology } = this.state;

      const user = await getStorage();

      let requestedBody = {
        donation: donation._id,
        addedBy: user.id,
        component: item.component._id,
        componentProfile: item.componentProfile._id,
        componentProfileCode: item.componentProfile._id,
        name: item.name,
        code: item.code,
        volume: item.volume,
        bloodGroup: bloodGrouping.bloodGroup || "unspecified",
        bloodGroupingResult: this.handleTestResult(
          bloodGrouping.questionnaire,
          "bloodGrouping"
        ),
        serologyResult: this.handleTestResult(
          serology.questionnaire,
          "serology"
        ),
        center: donation?.center?._id,
        centerSite: donation?.centerSite?._id,
        status: !bloodGrouping?._id || !serology._id ? "missingTest" : "active",
        type,
        productId: item._id,
      };

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      axios(options)
        .then((data) => {
          this.setState({
            ["isSubmitting_" + item.code]: false,
            ["isGenerated_" + item.code]: true,
            selectedItem: data.data,
          });

          toastMessage(
            "success",
            `Label for ${item.code} ${item.name} was generated successfully`
          );

          this.getComponentSeparations();
          this.getComponentTransformation();
        })
        .catch((error) => {
          this.setState({
            ["isSubmitting_" + item.code]: false,
            ["isGenerated_" + item.code]: false,
          });
          toastMessage("error", error);
        });
    }
  };

  renderItem = (item, type) => {
    return (
      <div style={{ display: "flex", alignItems: "center", minHeight: 50 }}>
        <div style={{ flex: 1, marginRight: "1rem" }}>
          <span>{item.code}-</span>
          <span>{item.name}</span>
        </div>
        {this.state["isGenerated_" + item.code] || item.status === "stockin" ? (
          <Button
            text={language[this.props.defaultLanguage].view}
            onPress={() => this.getProductInfo(item)}
          />
        ) : (
          <Button
            text={language[this.props.defaultLanguage].generate_label}
            onPress={() => onGenerateLabel(this, item, type)}
            isSubmitting={this.state["isSubmitting_" + item.code]}
          />
        )}
      </div>
    );
  };

  handleShowModal(model, selectedItem) {
    console.log({ selectedItem });
    this.setState({
      [model]: true,
      selectedItem,
    });
  }

  handleCloseModal(model) {
    this.setState({ [model]: false });
  }

  getProductInfo = async (item) => {
    if (!item?._id) return toastMessage("error", "Product id is missing");
    try {
      this.setState({ isLoading: true });

      const data = await fetchInventories(this.props.defaultLanguage, {
        productId: item._id,
      });

      this.setState({ isLoading: false, selectedItem: data[0] });

      if (data.length > 0) {
        this.handleShowModal("showLabelModal", data[0]);
        return;
      }

      toastMessage("error", "Inventory information is not available");
      // await onGenerateLabel(this, item, this.props.type);
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage(error);
    }
  };

  render() {
    return (
      <>
        <div>
          <>
            <div className="card-body">
              {this.state.isLoading ? (
                <center>
                  <Loading isFullScreen />
                  <p>{this.state.loadingMessage}</p>
                </center>
              ) : (
                <div>
                  <center className="card-header">
                    <h3>{this.state?.donation?.center?.name}</h3>
                    <p>
                      {language[this.props.defaultLanguage].donation_number}:
                      <span className="text-primary text-bold">
                        {this.state.donation.donationNumber}
                      </span>
                    </p>
                  </center>
                  {Object.keys(this.state.error).map((key, i) => {
                    return (
                      <div
                        className="alert alert-danger"
                        style={{ marginBottom: "1rem" }}
                      >
                        {this.state.error[key]}
                      </div>
                    );
                  })}
                  <div className="card-body">
                    <p className="text-bold">
                      {
                        language[this.props.defaultLanguage]
                          .component_separation
                      }
                    </p>
                    <br />
                    {this.state.separation.map((el, i) => {
                      return (
                        <div key={i}>{this.renderItem(el, "separation")}</div>
                      );
                    })}
                  </div>
                  <hr />
                  <div className="card-body">
                    <p className="text-bold">
                      {this.state.transformation.length > 0 &&
                        language[this.props.defaultLanguage]
                          .component_transformation}
                    </p>
                    <br />
                    {this.state.transformation.map((el, i) => {
                      return (
                        <div key={i}>
                          {this.renderItem(el, "transformation")}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <Modal
              handleClose={this.handleCloseModal.bind(this, "showLabelModal")}
              show={this.state.showLabelModal}
              showHeaderBottomBorder={false}
              title={this.state?.selectedItem?.code}
              size="lg"
            >
              <div style={{ minHeight: "60vh" }}>
                <PrintableLabel
                  handleCloseModal={this.handleCloseModal.bind(
                    this,
                    "showLabelModal"
                  )}
                  productId={this.state?.selectedItem?._id}
                />
              </div>
            </Modal>
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewLabel);
