import { getStorage } from "../../../../utils/storage";
import { fetchBloodTestHistory } from "../../../../utils/queries/bloodTestHistory";
import bloodTestQuery from "../../../../utils/queries/bloodTest/bloodTestQuery";
import toastMessage from "../../../../utils/toastMessage";
import axios from "axios";

const getBloodTesting = async (that, donation) => {
  try {
    that.setState({
      isLoading: true,
      loadingMessage: "Loading blood testing...",
    });
    const bloodTest = await bloodTestQuery(that.props.defaultLanguage, {
      donation,
    });

    // console.log("bloodTest", bloodTest);

    let bloodGrouping = {},
      serology = {};

    let groupingTestResult = [];
    let serologyTestResult = [];

    if (bloodTest.length > 0) {
      const data = await fetchBloodTestHistory(that.props.defaultLanguage, {
        bloodTest: bloodTest[0]._id,
      });

      data?.forEach((el) => {
        if (el.questionnaireType === "bloodGrouping") {
          bloodGrouping = el;
          groupingTestResult.push(el);
        }

        if (el.questionnaireType === "serology") {
          serology = el;

          serologyTestResult.push(el);
        }
      });

      that.setState({
        isLoading: false,
      });

      let resultABO = [];
      let resultSerology = [];

      if (groupingTestResult.length === 0) {
        resultABO = "Blood grouping missing result";
      } else if (groupingTestResult.length === 1) {
        resultABO = "Blood grouping Missing 2nd Entry";
      } else {
        resultABO = bloodGrouping;
      }

      if (serologyTestResult.length === 0) {
        resultSerology = "Serology missing result";
      } else if (serologyTestResult.length === 1) {
        resultSerology = "Serology Missing 2nd Entry";
      } else {
        resultSerology = serology;
      }

      return { bloodGrouping: resultABO, serology: resultSerology };
    }
  } catch (error) {
    that.setState({
      isLoading: false,
    });
    return toastMessage("error", error);
  }
};

export const handleFetchTestResult = (res = [], type) => {
  let textRes = [];

  if (res.questionnaire) {
    res.questionnaire.forEach((el) => {
      if (el.printingEnabled && el.answer) {
        if (el.answer.value && el.settings && el.settings[el.answer.value]) {
          const v =
            (el.settings[el.answer.value]?.abbreviation || el.questionText) +
            ":" +
            el.answer.value +
            " ";

          if (!textRes.includes(v)) {
            textRes.push(v);
          }
        } else if (el.answer.value) {
          const v = el.questionText + ":" + el.answer.value + " ";
          if (!textRes.includes(v)) {
            textRes.push(v);
          }
        } else if (typeof el.answer === "string") {
          const v = el.questionText + ":" + el.answer + " ";

          if (!textRes.includes(v)) {
            textRes.push(v);
          }
        }
      }
    });

    let resultText = getGroupedAnswers(textRes.join(","));

    return resultText !== "" ? resultText : `Missing ${type}`;
  } else {
    return res;
  }
};

const getGroupedAnswers = (str) => {
  const answersMap = new Map();
  const answersArray = str.split(",").map((item) => item.trim());

  answersArray.forEach((item) => {
    const [question, answer] = item.split(":").map((i) => i.trim());

    if (answer) {
      // If the answer already exists, add the question to the existing answer
      if (answersMap.has(answer)) {
        answersMap.get(answer).push(question);
      } else {
        // Otherwise, create a new entry
        answersMap.set(answer, [question]);
      }
    }
  });

  // Construct the output string
  const result = [];
  answersMap.forEach((questions, answer) => {
    result.push(`${questions.join(",")}:${answer}`);
  });

  return result.join(", ");
};

const validateForm = (that) => {
  let { error, donation } = that.state;

  if (!donation && !donation?._id) {
    error.donation = "Donation is required";
  }

  that.setState({ error });
};

export const onGenerateLabel = async (
  that,
  item,
  type,
  method = "POST",
  id
) => {
  await validateForm(that);
  // if (Object.keys(that.state.error).length === 0) {
  if (that.state.donation?._id) {
    that.setState({
      ["isSubmitting_" + item.code]: true,
    });

    let { donation } = that.state;

    const user = await getStorage();

    let { bloodGrouping = {}, serology = {} } =
      (await getBloodTesting(that, donation?._id || donation)) || {};

    if (!item._id) return;

    let requestedBody = {
      donation: donation._id,
      addedBy: user.id,
      component: item?.component?._id,
      componentProfile: item?.componentProfile?._id,
      componentProfileCode: item.componentProfile.code,
      name: item.name,
      code: item.code,
      volume: item.volume,
      bloodGroup: bloodGrouping.bloodGroup || "N/A",
      // bloodGroupingResult: handleFetchTestResult(
      //   bloodGrouping,
      //   "bloodGrouping"
      // ),
      // serologyResult: handleFetchTestResult(serology, "serology"),
      center: donation?.center?._id,
      centerSite: donation?.centerSite?._id,
      status: "pending",
      type,
      productId: item.productId || item._id,
    };

    if (method === "PUT") {
      requestedBody.id = id;
    }

    const options = {
      method,
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };
    return axios(options)
      .then((data) => {
        that.setState({
          ["isSubmitting_" + item.code]: false,
          ["isGenerated_" + item.code]: true,
          selectedItem: data.data,
        });

        toastMessage(
          "success",
          `Label for ${item.code} ${item.name} was generated successfully`
        );

        //   that.getComponentSeparations();
        //   that.getComponentTransformation();

        return data.data;
      })
      .catch((error) => {
        console.log(error);
        that.setState({
          ["isSubmitting_" + item.code]: false,
          ["isGenerated_" + item.code]: false,
        });
        toastMessage("error", error);

        return;
      });
  }
};
