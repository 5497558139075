import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import { Empty } from "../../Empty";
import moment from "moment";
import TESTForm from "../TESTForm";
import { fetchBloodTestQuestionnaire } from "../../../utils/queries/bloodTest/questionnaireQuery";
import {
  generateRandomNumber,
  generateRandomString,
} from "../../../utils/random";
import accessMenu from "../../../utils/accessMenu";
import { handleValidateMatching } from "../utils";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class Serology extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      currentTime: moment().format("lll"),
      donation: {},
      questionnaire: [],
      forms: {},
      error: {},
      disabled: true,
      showForm: true,
      confirmationModal: false,
      activateDonation: true,
      code: "",
      misMatchFields: [],
      previousTestResults: props.previousTestResults,
    };
  }

  componentDidMount = async () => {
    const {
      canCreateBloodTestSerology,
      canConfirmBloodTestSerology,
      canUpdateBloodTestSerology,
    } = accessMenu();

    this.setState({
      code: "SR00" + (this.state.previousTestResults.length + 1),
    });

    if (this.props._id) {
      this.setState({
        ...this.props,
      });
    }

    await this.getQuestionnaires(true);

    if (
      canCreateBloodTestSerology ||
      canConfirmBloodTestSerology ||
      canUpdateBloodTestSerology
    ) {
      this.setState({
        disabled: false,
      });
    } else {
      return this.setState({
        showForm: false,
        disabled: true,
      });
    }
  };

  getQuestionnaires = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data } = await fetchBloodTestQuestionnaire(defaultLanguage, {
        page: 1,
        limit: 100,
        type: "serology",
      });

      const modifiedQuestions = [];

      // data.forEach(el => {
      //   // if (this.props._id) {
      //     // this.setState({
      //     //   ...this.props,
      //     // });

      // })

      if (
        Array.isArray(this.props.questionnaire) &&
        this.props.questionnaire.length > 0
      ) {
        const modifiedQuestions = data.map((el) => {
          // Find the matching questionnaire item based on questionText
          const matchedQuestion = this.props.questionnaire.find(
            (qEl) => qEl.questionText === el.questionText
          );

          // Log the matched question (for debugging purposes)
          // console.log(matchedQuestion);

          // If a match is found, update the answer
          if (matchedQuestion) {
            return { ...el, answer: matchedQuestion.answer };
          }

          // Return the original element if no match is found
          return el;
        });

        // Update the component state with the modified questions
        this.setState({ questionnaire: modifiedQuestions, isLoading: false });
        return;
      }

      this.setState({
        questionnaire: data,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeQuestionnaire = async ({ value, index }) => {
    let { questionnaire } = this.state;

    let question = questionnaire[index];

    let answerValue = value.target ? value.target.value : value;

    questionnaire[index]["answer"] = answerValue;

    delete questionnaire[index]["error"];

    this.setState({
      questionnaire,
    });

    const answerValueValue = answerValue?.value || answerValue;

    handleValidateMatching({
      that: this,
      field: questionnaire[index]?.questionText,
      value: answerValueValue,
      questionnaireIndex: index,
    });

    if (
      question?.settings &&
      question?.settings?.[answerValueValue] &&
      Object.keys(question?.settings?.[answerValueValue])?.includes(
        "activateDonation"
      )
    ) {
      this.setState({
        activateDonation:
          question?.settings?.[answerValueValue]?.activateDonation,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let { questionnaire, error, code, bloodGroup } = this.state;

    questionnaire.forEach((el) => {
      if (el.required && (!el.answer || el.answer === "")) {
        el.error = `${el.questionText} ${
          language[this.props.defaultLanguage].is_required
        }`;
      }
    });

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    this.setState({ questionnaire, error });
  }

  onSubmit = async (status) => {
    const { questionnaire, error, code } = this.state;

    const { testId } = this.props;

    await this.validateForm();

    let questionnaireError = questionnaire
      .map((el) => {
        if (el.error) {
          return el.error;
        }
        return null;
      })
      .filter((error) => error !== undefined && error !== null);

    if (Object.keys(error).length > 0) return;

    if (questionnaireError.length > 0) return;

    this.setState({
      isSubmitting: true,
    });

    await this.handleUpdateQuestionnaire();

    let url = `${API_URL}/bloodTestHistory`,
      method = "POST",
      user = await getStorage();
    let { _id } = this.props;

    let requestedBody = {
      testBy: user.id,
      bloodTest: testId,
      questionnaireType: "serology",
      questionnaire,
      code,
    };

    if (_id && _id !== "") {
      method = "PUT";
      requestedBody.id = _id;
      delete requestedBody.testBy;
    }

    if (status) {
      requestedBody.status = status;
    }

    const options = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };

    axios(options)
      .then(async (data) => {
        this.setState({
          isSubmitting: false,
        });

        if (this.props.canConfirm) {
          await this.handleValidation();
        }

        toastMessage(
          "success",
          language[this.props.defaultLanguage][
            method === "PUT"
              ? "update_bloodtest_success"
              : "add_bloodtest_success"
          ]
        );

        this.props.getData && this.props.getData();
        this.props.handleCloseModal && this.props.handleCloseModal();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", error);
      });
  };

  handleOpenModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleValidation = async () => {
    try {
      const { questionnaire, code, error } = this.state;

      await this.validateForm();

      if (Object.keys(error).length > 0) return;

      this.setState({ isValidating: true });

      const { testId } = this.props;

      const user = await getStorage();

      let requestedBody = {
        bloodTestId: testId,
        type: "serology",
        questionnaire,
        code,
      };

      const options = {
        method: "POST",
        url: `${API_URL}/bloodTest/comfirmTest`,
        data: requestedBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options).then((data) => {
        this.setState({
          isValidating: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].confirm_blood_test
        );
        this.props.getData && this.props.getData();
        this.props.handleCloseModal && this.props.handleCloseModal();
      });
    } catch (error) {
      this.setState({
        isValidating: false,
      });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].confirm_blood_test_not_same
      );
    }
  };

  handleUpdateQuestionnaire = async () => {
    for (let el of this.state.previousTestResults) {
      try {
        let url = `${API_URL}/bloodTestHistory`,
          user = await getStorage();

        let requestedBody = {
          bloodGroup: el.bloodGroup?.value || el.bloodGroup,
          questionnaire: el.questionnaire,
          id: el._id,
          bloodTest: el.bloodTest._id,
        };

        delete requestedBody._id;

        return await axios({
          url,
          method: "PUT",
          data: requestedBody,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  renderChangePrevEntry({ field, questionnaireIndex, type, options }) {
    if (this.state.previousTestResults.length > 0) {
      return this.state.previousTestResults?.map((el, i) => {
        return this.renderChangePrevEntryInput({
          label: el.code,
          field,
          questionnaireIndex,
          type,
          options,
          testIndex: i,
        });
      });
    }
  }

  renderChangePrevEntryInput({
    type,
    options,
    field,
    testIndex,
    questionnaireIndex,
    label,
  }) {
    let value;

    if (questionnaireIndex !== undefined && testIndex !== undefined) {
      value =
        this.props?.previousTestResults[testIndex].questionnaire[
          questionnaireIndex
        ].answer;
    } else if (testIndex !== undefined) {
      value = this.props?.previousTestResults[testIndex][field];
    }

    if (type === "dropdown") {
      return (
        <Select
          key={testIndex}
          options={options}
          label={label + "-ENTRY-" + field}
          value={
            typeof value === "string"
              ? options.map((el) => el.value === value && el)
              : value
          }
          onChange={(e) =>
            this.handleChangePrevEntry({
              field,
              index: testIndex,
              value: e.target ? e.target.value : e,
              questionnaireIndex,
            })
          }
        />
      );
    }

    return (
      <Input
        label={label + "-ENTRY-" + field}
        value={value}
        onChange={(e) =>
          this.handleChangePrevEntry({
            field,
            index: testIndex,
            value: e.target ? e.target.value : e,
            questionnaireIndex,
          })
        }
      />
    );
  }

  handleChangePrevEntry({ index, field, questionnaireIndex, value }) {
    if (questionnaireIndex !== undefined) {
      this.state.questionnaire[questionnaireIndex]["answer"] = value;

      this.state.previousTestResults[index]["questionnaire"][
        questionnaireIndex
      ] = this.state.questionnaire[questionnaireIndex];

      console.log({
        v1: this.state.questionnaire[questionnaireIndex]["answer"],
        v2: value,
      });

      delete this.state.questionnaire[questionnaireIndex]["error"];

      handleValidateMatching({
        that: this,
        field,
        value: value?.value || value,
        questionnaireIndex,
      });
    } else {
      this.state.previousTestResults[index][field] = value;

      handleValidateMatching({ that: this, field, value: this.state[field] });
    }

    this.setState({ previousTestResults: this.state.previousTestResults });
  }

  render() {
    if (!this.state.showForm)
      return (
        <Empty
          title={language[this.props.defaultLanguage].no_access}
          icon="help"
          description={
            language[this.props.defaultLanguage].no_access_description
          }
        />
      );
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <div className="card mb-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        label={language[this.props.defaultLanguage].code}
                        placeholder={
                          language[this.props.defaultLanguage].code_placeholder
                        }
                        value={this.state?.code}
                        error={this.state.error.code}
                        onChange={(e) => this.onChangeText("code", e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {this.state.questionnaire.map((form, index) => {
                  const options = form?.options?.map((el) => {
                    return {
                      label: el,
                      value: el,
                    };
                  });
                  return (
                    <div className="col-md-4" key={index}>
                      <TESTForm
                        label={form.questionText}
                        error={form.error}
                        {...form}
                        options={form?.options?.map((el) => {
                          return {
                            label: el,
                            value: el,
                          };
                        })}
                        onChange={(e) =>
                          this.onChangeQuestionnaire({
                            index,
                            form,
                            value: e,
                          })
                        }
                        // disabled={this.state.disabled}
                      />
                      {this.state.misMatchFields.includes(form.questionText) &&
                        this.renderChangePrevEntry({
                          field: form.questionText,
                          questionnaireIndex: index,
                          type:
                            form.answerType === "multipleChoice"
                              ? "dropdown"
                              : "",
                          options: options,
                        })}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="modal-footer">
              <Button
                text={language[this.props.defaultLanguage].cancel}
                onPress={() => this.props.handleCloseModal()}
                className="btn-default"
              />
              <Button
                text={language[this.props.defaultLanguage].submit}
                onPress={() => this.onSubmit()}
                isSubmitting={this.state.isSubmitting}
                disabled={this.state.questionnaire.length === 0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Serology);
