import React from "react";
import "./styles.css";
import language from "../../../language";
import { connect } from "react-redux";
import PersonalInformation from "./PersonalInformation";
import HealthInformation from "./HealthInformation";
import Acknowledgment from "./Acknowledgment";
import CenterReceiver from "./CenterReceiver";
import { Button } from "../../Button";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import donorQuery from "../../../utils/queries/donorsQuery";
import { fetchPreAssessmentInformation } from "../../../utils/queries/preAssessmentQuery";
import { v4 as uuidv4 } from "uuid";
import { Input, Select } from "../../Input";
import icons from "../../../constants/icons";
import formatSelectData from "../../../utils/formatSelectData";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";
import accountQuery from "../../../utils/queries/accountQuery";
import { getStorage } from "../../../utils/storage";

const defaultQuestionnaires = [
  {
    key: "weightLoss",
    value: "No",
  },
  {
    key: "swollenGlands",
    value: "No",
  },
  {
    key: "syphillis",
    value: "No",
  },
  {
    key: "areYouPregnant",
    value: "No",
  },
  {
    key: "doYouHaveChildUnder9Months",
    value: "No",
  },
  {
    key: "haveYouHadAbortionInTheLastSixMonths",
    value: "No",
  },
  {
    key: "areYouFeelingHealthAndWell",
    value: "No",
  },

  {
    key: "areYouTakingAnyMedication",
    value: "No",
  },

  {
    key: "doYouHaveAnyWoundOrCutaneousDisease",
    value: "No",
  },

  {
    key: "didYouRecentlyTravelOutsideRwandaRecently",
    value: "No",
  },
  {
    key: "haveYouTakenAspirin",
    value: "No",
  },

  {
    key: "haveYouHadMalaria",
    value: "No",
  },

  {
    key: "vaccine",
    value: "No",
  },

  {
    key: "dentalExtraction",
    value: "No",
  },
  {
    key: "continuousLowGradeFeverOrAnyDisease",
    value: "No",
  },
  {
    key: "tattooingOrEarPiercing",
    value: "No",
  },

  {
    key: "surgeryOrCircumcision",
    value: "No",
  },

  {
    key: "endoscopy",
    value: "No",
  },

  {
    key: "bloodTransfusion",
    value: "No",
  },

  {
    key: "heartDisease",
    value: "No",
  },

  {
    key: "kidneyDisease",
    value: "No",
  },

  {
    key: "cancer",
    value: "No",
  },

  {
    key: "diabetes",
    value: "No",
  },

  {
    key: "tuberculosis",
    value: "No",
  },

  {
    key: "abnormalBleedingTendency",
    value: "No",
  },
  {
    key: "asthma",
    value: "No",
  },
  {
    key: "hepatitisBOrC",
    value: "No",
  },

  {
    key: "HIVAIDS",
    value: "No",
  },
  {
    key: "gonorrhea",
    value: "No",
  },

  {
    key: "neighborWithInfectious",
    value: "No",
  },

  {
    key: "epilepsy",
    value: "No",
  },

  {
    key: "repeatedDiarrhea",
    value: "No",
  },

  {
    key: "weightLoss",
    value: "No",
  },
  {
    key: "swollenGlands",
    value: "No",
  },
  {
    key: "syphillis",
    value: "No",
  },

  {
    key: "areYouPregnant",
    value: "No",
  },

  {
    key: "doYouHaveChildUnder9Months",
    value: "No",
  },

  {
    key: "haveYouHadAbortionInTheLastSixMonths",
    value: "No",
  },

  {
    key: "doYouHaveChildUnder9Months",
    value: "No",
  },

  {
    key: "areYouMenstrualPeriod",
    value: "No",
  },
];

class PreAssessmentRegistration extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    questionnaires: {},
    donor: {},
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    let { questionnaires } = this.state;

    for (let el of defaultQuestionnaires) {
      questionnaires[el.key] = el.value;
    }

    this.setState({ questionnaires });

    if (this.props.donorNumber || this.props.donorId) {
      questionnaires.donorNumber =
        this.props.donorNumber || this.props.donorId || "";
      this.setState(
        {
          questionnaires,
        },
        () => {
          this.handleDonorSearch();
        }
      );
    }

    if (this.props.preassessmentId) {
      this.getAssessmentAnswer(this.props.preassessmentId);
    }
  };

  getAccounts = async (q) => {
    try {
      this.setState({
        isFetchingAccount: true,
      });

      const data = await accountQuery(this.props.defaultLanguage, {
        type: "dropdown",
        q: q ? q : undefined,
        page: 1,
        limit: 50,
      });

      this.setState({
        accounts: data,
        isFetchingAccount: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingAccount: false });

      return [];
    }
  };

  onChangeText(field, e) {
    let { error, questionnaires = {} } = this.state;

    delete error[field];

    questionnaires[field] = e.target ? e.target.value : e;

    this.setState({ error, questionnaires });
  }

  handleCheck = async (field, value) => {
    let { error, questionnaires = {} } = this.state;

    delete error[field];

    questionnaires[field] = value;

    if (!value) {
      questionnaires[field] = !value;
    }

    if (field === "isAgree") {
      await this.onValidateForm();

      if (Object.keys(error).length > 0) {
        toastMessage(
          "error",
          `${Object.keys(error).length} field(s) are mandatory`
        );
      }
    }

    this.setState({ questionnaires, error });
  };

  handleDecisionCheck = async (field, value) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: value });
  };

  onValidateForm() {
    let { error, questionnaires, donor } = this.state;

    if (!donor || !donor._id) {
      error.donor = language[this.props.defaultLanguage].donor_required;
    }

    if (!questionnaires.center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    if (!questionnaires.centerSite) {
      error.centerSite =
        language[this.props.defaultLanguage].center_site_required;
    }

    if (questionnaires.birthPlace === "") {
      error.birthPlace =
        language[this.props.defaultLanguage].birth_place_required;
    }

    if (questionnaires.occupation === "") {
      error.occupation =
        language[this.props.defaultLanguage].occupation_required;
    }

    // if (!questionnaires.province) {
    //   error.province = language[this.props.defaultLanguage].province_required;
    // }

    // if (!questionnaires.district) {
    //   error.district = language[this.props.defaultLanguage].district_required;
    // }

    // if (!questionnaires.sector) {
    //   error.sector = language[this.props.defaultLanguage].sector_required;
    // }

    // if (!questionnaires.village) {
    //   error.village = language[this.props.defaultLanguage].village_required;
    // }

    // if (!questionnaires.cell) {
    //   error.cell = language[this.props.defaultLanguage].cell_required;
    // }

    if (!questionnaires.areYouMarried) {
      error.areYouMarried =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.areYouFeelingHealthAndWell) {
      error.areYouFeelingHealthAndWell =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.areYouTakingAnyMedication) {
      error.areYouTakingAnyMedication =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.doYouHaveAnyWoundOrCutaneousDisease) {
      error.doYouHaveAnyWoundOrCutaneousDisease =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.didYouRecentlyTravelOutsideRwandaRecently) {
      error.didYouRecentlyTravelOutsideRwandaRecently =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.haveYouTakenAspirin) {
      error.haveYouTakenAspirin =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.repeatedDiarrhea) {
      error.repeatedDiarrhea =
        language[this.props.defaultLanguage].repeated_diarrhea;
    }

    // if (!questionnaires.haveYouBittenByInsect) {
    //   error.haveYouBittenByInsect =
    //     language[this.props.defaultLanguage].answer_this_question;
    // }

    if (!questionnaires.haveYouHadMalaria) {
      error.haveYouHadMalaria =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.vaccine) {
      error.vaccine = language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.dentalExtraction) {
      error.dentalExtraction =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.continuousLowGradeFeverOrAnyDisease) {
      error.continuousLowGradeFeverOrAnyDisease =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.tattooingOrEarPiercing) {
      error.tattooingOrEarPiercing =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.dentalExtraction) {
      error.dentalExtraction =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.surgeryOrCircumcision) {
      error.surgeryOrCircumcision =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.endoscopy) {
      error.endoscopy =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.bloodTransfusion) {
      error.bloodTransfusion =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.heartDisease) {
      error.heartDisease =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.kidneyDisease) {
      error.kidneyDisease =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.cancer) {
      error.cancer = language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.diabetes) {
      error.diabetes =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.tuberculosis) {
      error.tuberculosis =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.abnormalBleedingTendency) {
      error.abnormalBleedingTendency =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.asthma) {
      error.asthma = language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.hepatitisBOrC) {
      error.hepatitisBOrC =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.hepatitisBOrC) {
      error.hepatitisBOrC =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.HIVAIDS) {
      error.HIVAIDS = language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.gonorrhea) {
      error.gonorrhea =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.neighborWithInfectious) {
      error.neighborWithInfectious =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (donor.gender === "female") {
      if (!questionnaires.areYouPregnant) {
        error.areYouPregnant =
          language[this.props.defaultLanguage].answer_this_question;
      }
      if (!questionnaires.doYouHaveChildUnder9Months) {
        error.doYouHaveChildUnder9Months =
          language[this.props.defaultLanguage].answer_this_question;
      }

      if (!questionnaires.haveYouHadAbortionInTheLastSixMonths) {
        error.haveYouHadAbortionInTheLastSixMonths =
          language[this.props.defaultLanguage].answer_this_question;
      }

      if (!questionnaires.areYouMenstrualPeriod) {
        error.areYouMenstrualPeriod =
          language[this.props.defaultLanguage].areYouMenstrualPeriod;
      }
    }

    if (!questionnaires.weightLoss) {
      error.weightLoss =
        language[this.props.defaultLanguage].answer_this_question;
    }
    if (!questionnaires.HIVAIDS) {
      error.HIVAIDS = language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.epilepsy) {
      error.epilepsy =
        language[this.props.defaultLanguage].answer_this_question;
    }

    if (!questionnaires.gonorrhea) {
      error.gonorrhea =
        language[this.props.defaultLanguage].answer_this_question;
    }

    // if (!questionnaires.occupation) {
    //   error.occupation =
    //     language[this.props.defaultLanguage].answer_this_question;
    // }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.onValidateForm();

    let { error, questionnaires, donor } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({ isSubmitting: true });

      const lng = this.props.defaultLanguage;
      const duplicateId = uuidv4();

      let healthQuestionnaire = [
        {
          answerType: "occupation",
          questionText: language[lng].occupation,
          answer: questionnaires["occupation"]?.label,
          duplicateId,
        },
        // {
        //   answerType: "province",
        //   questionText: language[lng].province,
        //   answer: questionnaires["province"].value,
        //   duplicateId,
        // },
        // {
        //   answerType: "district",
        //   questionText: language[lng].district,
        //   answer: questionnaires["district"].value,
        //   duplicateId,
        // },
        // {
        //   answerType: "sector",
        //   questionText: language[lng].sector,
        //   answer: questionnaires["sector"].value,
        //   duplicateId,
        // },
        // {
        //   answerType: "cell",
        //   questionText: language[lng].cell,
        //   answer: questionnaires["cell"].value,
        //   duplicateId,
        // },
        // {
        //   answerType: "village",
        //   questionText: language[lng].village,
        //   answer: questionnaires["village"].value,
        //   duplicateId,
        // },
        {
          answerType: "areYouMarried",
          questionText: language[lng].are_you_married,
          answer: questionnaires["areYouMarried"],
          duplicateId,
        },
        {
          answerType: "areYouFeelingHealthAndWell",
          questionText: language[lng].are_feeling_healthy_and_well,
          answer: questionnaires["areYouFeelingHealthAndWell"],
          duplicateId,
        },
        {
          answerType: "areYouTakingAnyMedication",
          questionText: language[lng].are_you_taking_any_medication,
          answer: questionnaires["areYouTakingAnyMedication"],
          duplicateId,
        },
        {
          answerType: "doYouHaveAnyWoundOrCutaneousDisease",
          questionText:
            language[lng].do_you_have_any_wound_or_cutaneous_disease,
          answer: questionnaires["doYouHaveAnyWoundOrCutaneousDisease"],
          duplicateId,
        },
        {
          answerType: "didYouRecentlyTravelOutsideRwandaRecently",
          questionText: language[lng].did_you_travel_outside_rwanda_recently,
          answer: questionnaires["didYouRecentlyTravelOutsideRwandaRecently"],
          duplicateId,
        },
        {
          answerType: "haveYouTakenAspirin",
          questionText: language[lng].have_taken_an_aspirin,
          answer: questionnaires["haveYouTakenAspirin"],
          duplicateId,
        },
        // {
        //   answerType: "haveYouBittenByInsect",
        //   questionText: language[lng].have_you_bitten_by_insect,
        //   answer: questionnaires["haveYouBittenByInsect"],
        //   duplicateId,
        // },

        {
          answerType: "haveYouHadMalaria",
          questionText: language[lng].have_you_had_malaria,
          answer: questionnaires["haveYouHadMalaria"],
          duplicateId,
        },
        {
          answerType: "vaccine",
          questionText: language[lng].vaccine,
          answer: questionnaires["vaccine"],
          duplicateId,
        },
        {
          answerType: "dentalExtraction",
          questionText: language[lng].dental_extraction,
          answer: questionnaires["dentalExtraction"],
          duplicateId,
        },
        {
          answerType: "continuousLowGradeFeverOrAnyDisease",
          questionText: language[lng].continuous_low_grade_fever_any_disease,
          answer: questionnaires["continuousLowGradeFeverOrAnyDisease"],
          duplicateId,
        },
        {
          answerType: "tattooingOrEarPiercing",
          questionText: language[lng].tattooing_or_ear_piercing,
          answer: questionnaires["tattooingOrEarPiercing"],
          duplicateId,
        },
        {
          answerType: "surgeryOrCircumcision",
          questionText: language[lng].surgery_or_circumcision,
          answer: questionnaires["surgeryOrCircumcision"],
          duplicateId,
        },
        {
          answerType: "endoscopy",
          questionText: language[lng].endoscopy,
          answer: questionnaires["endoscopy"],
          duplicateId,
        },
        {
          answerType: "bloodTransfusion",
          questionText: language[lng].blood_transfusion,
          answer: questionnaires["bloodTransfusion"],
          duplicateId,
        },
        {
          answerType: "heartDisease",
          questionText: language[lng].heart_disease,
          answer: questionnaires["heartDisease"],
          duplicateId,
        },
        {
          answerType: "kidneyDisease",
          questionText: language[lng].kidney_disease,
          answer: questionnaires["kidneyDisease"],
          duplicateId,
        },
        {
          answerType: "cancer",
          questionText: language[lng].cancer,
          answer: questionnaires["cancer"],
          duplicateId,
        },
        {
          answerType: "diabetes",
          questionText: language[lng].diabetes,
          answer: questionnaires["diabetes"],
          duplicateId,
        },
        {
          answerType: "tuberculosis",
          questionText: language[lng].tuberculosis,
          answer: questionnaires["tuberculosis"],
          duplicateId,
        },

        {
          answerType: "abnormalBleedingTendency",
          questionText: language[lng].abnormal_bleeding_tendency,
          answer: questionnaires["abnormalBleedingTendency"],
          duplicateId,
        },
        {
          answerType: "asthma",
          questionText: language[lng].asthma,
          answer: questionnaires["asthma"],
          duplicateId,
        },
        {
          answerType: "hepatitisBOrC",
          questionText: language[lng].hepatitis_b_or_c,
          answer: questionnaires["hepatitisBOrC"],
          duplicateId,
        },
        {
          answerType: "HIVAIDS",
          questionText: language[lng].HIVAIDS,
          answer: questionnaires["HIVAIDS"],
          duplicateId,
        },
        {
          answerType: "gonorrhea",
          questionText: language[lng].gonorrhea,
          answer: questionnaires["gonorrhea"],
          duplicateId,
        },
        {
          answerType: "neighborWithInfectious",
          questionText:
            language[lng].neighbor_with_infectious_such_as_hapatitis_b_or_c,
          answer: questionnaires["neighborWithInfectious"],
          duplicateId,
        },
        {
          answerType: "areYouPregnant",
          questionText: language[lng].are_you_pregnant,
          answer: questionnaires["areYouPregnant"],
          duplicateId,
        },
        {
          answerType: "doYouHaveChildUnder9Months",
          questionText: language[lng].do_you_have_a_child_who_is_under_9_months,
          answer: questionnaires["doYouHaveChildUnder9Months"],
          duplicateId,
        },
        {
          answerType: "haveYouHadAbortionInTheLastSixMonths",
          questionText: language[lng].do_you_have_a_child_who_is_under_9_months,
          answer: questionnaires["haveYouHadAbortionInTheLastSixMonths"],
          duplicateId,
        },
        {
          answerType: "weightLoss",
          questionText: language[lng].weight_loss,
          answer: questionnaires["weightLoss"],
          duplicateId,
        },
        {
          answerType: "swollenGlands",
          questionText: language[lng].swollen_glands,
          answer: questionnaires["swollenGlands"],
          duplicateId,
        },
        {
          answerType: "syphillis",
          questionText: language[lng].syphillis,
          answer: questionnaires["syphillis"],
          duplicateId,
        },
        {
          answerType: "repeatedDiarrhea",
          questionText: language[lng].repeatedDiarrhea,
          answer: questionnaires["repeatedDiarrhea"],
          duplicateId,
        },
        {
          answerType: "areYouMenstrualPeriod",
          questionText: language[lng].areYouMenstrualPeriod,
          answer: questionnaires["areYouMenstrualPeriod"],
          duplicateId,
        },
        {
          answerType: "epilepsy",
          questionText: language[lng].epilepsy,
          answer: questionnaires["epilepsy"],
          duplicateId,
        },
        {
          answerType: "repeatedDiarrhea",
          questionText: language[lng].repeated_diarrhea,
          answer: questionnaires["repeatedDiarrhea"],
          duplicateId,
        },
      ];

      let method = "POST";
      let requestData = {
        donor: donor._id,
        healthQuestionnaire,
        center: questionnaires["center"].value,
        centerSite: questionnaires["centerSite"].value,
        isEligible: this.state.isEligible === "Yes" ? true : false,
        selector: this.state.selector?.value,
      };

      if (this.props.preassessmentId || this.props._id) {
        method = "PUT";
        requestData.id = this.props.preassessmentId || this.props._id;
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/preDonationAssessment`,
        data: requestData,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("success", "Data submitted successfully");

          if (this.props.route === "/user") {
            return (window.location.href = "/user/pre-assessment");
          }
          window.location.href = `${this.props.route}/donors/${donor._id}`;
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage("error", "Unable to submit data, try again");
        });
    }
  };

  handleDonorSearch = async () => {
    let { error, questionnaires } = this.state;

    if (!questionnaires.donorNumber || questionnaires.donorNumber === "") {
      error.donorNumber = language[this.props.defaultLanguage].donor_required;

      this.setState({ error });

      return;
    }

    this.setState({ isSearchingDonor: true });

    console.log(this.state);

    await this.handleQueryDonorInfo(questionnaires.donorNumber);
  };

  handleQueryDonorInfo = async (donorNumber) => {
    try {
      const data = await donorQuery(this.props.defaultLanguage, {
        donorNumber,
      });

      let donor = {};

      if (data.length > 0) {
        donor = {
          _id: data[0]._id,
          firstName: data[0].firstName,
          lastName: data[0].lastName,
          email: data[0].email,
          dateOfBirth: data[0].dateOfBirth,
          gender: data[0].gender,
          province: data[0].province,
          district: data[0].district,
          sector: data[0].sector,
          cell: data[0].cell,
          village: data[0].village,
        };
      }

      this.setState({ donor, isSearchingDonor: false });
    } catch (error) {
      this.setState({ isSearchingDonor: false });
      toastMessage(
        "error",
        "Unable to search donor information: " + JSON.stringify(error)
      );
    }
  };

  getAssessmentAnswer = async (preassessmentId) => {
    if (!preassessmentId) return;

    this.setState({ isFetchingAssessmentAnswer: true });

    try {
      const data = await fetchPreAssessmentInformation(
        this.props.defaultLanguage,
        {
          id: preassessmentId,
        }
      );

      let dataInfo = {};

      if (data.length > 0) {
        dataInfo = data[0];

        this.populateInfoIntoForm(dataInfo);
      }

      this.setState({ isFetchingAssessmentAnswer: false });
    } catch (error) {
      this.setState({ isFetchingAssessmentAnswer: false });
      toastMessage(
        "error",
        language[this.props.defaultLanguage].unable_to_fetch_data +
          JSON.stringify(error)
      );
    }
  };

  populateInfoIntoForm(info) {
    let { questionnaires } = this.state;

    if (info.center) {
      questionnaires["center"] = formatSelectData(info.center, "name", "_id");
    }

    if (info.centerSite) {
      questionnaires["centerSite"] = formatSelectData(
        info.centerSite,
        "name",
        "_id"
      );

      info.healthQuestionnaire.map((el) => {
        if (el.answerType === "occupation") {
          questionnaires.occupation = el.answer;
        }

        if (el.answerType === "occupation") {
          questionnaires.occupation = {
            label: el.answer,
            value: el.answer,
          };
        }
        if (this.state?.donor?.province) {
          questionnaires.province = {
            label: this.state?.donor?.province,
            value: this.state?.donor?.province,
          };
        }
        if (this.state?.donor?.district) {
          questionnaires.district = {
            label: this.state?.donor?.district,
            value: this.state?.donor?.district,
          };
        }
        console.log("********************************", el.answerType);
        if (el.answerType === "weightLoss") {
          questionnaires.weightLoss = el.answer;
        }
        if (el.answerType === "swollenGlands") {
          questionnaires.swollenGlands = el.answer;
        }
        if (el.answerType === "syphillis") {
          questionnaires.syphillis = el.answer;
        }
        if (el.answerType === "areYouPregnant") {
          questionnaires.areYouPregnant = el.answer;
        }
        if (el.answerType === "doYouHaveChildUnder9Months") {
          questionnaires.doYouHaveChildUnder9Months = el.answer;
        }
        if (el.answerType === "haveYouHadAbortionInTheLastSixMonths") {
          questionnaires.haveYouHadAbortionInTheLastSixMonths = el.answer;
        }

        if (this.state?.donor?.cell) {
          questionnaires.province = {
            label: this.state?.donor?.cell,
            value: this.state?.donor?.cell,
          };
        }

        if (this.state?.donor?.sector) {
          questionnaires.province = {
            label: this.state?.donor?.sector,
            value: this.state?.donor?.sector,
          };
        }

        if (this.state?.donor?.village) {
          questionnaires.province = {
            label: this.state?.donor?.village,
            value: this.state?.donor?.village,
          };
        }

        if (el.answerType === "areYouMarried") {
          questionnaires.areYouMarried = el.answer;
        }

        if (el.answerType === "areYouFeelingHealthAndWell") {
          questionnaires.areYouFeelingHealthAndWell = el.answer;
        }

        if (el.answerType === "areYouTakingAnyMedication") {
          questionnaires.areYouTakingAnyMedication = el.answer;
        }

        if (el.answerType === "doYouHaveAnyWoundOrCutaneousDisease") {
          questionnaires.doYouHaveAnyWoundOrCutaneousDisease = el.answer;
        }

        if (el.answerType === "didYouRecentlyTravelOutsideRwandaRecently") {
          questionnaires.didYouRecentlyTravelOutsideRwandaRecently = el.answer;
        }

        if (el.answerType === "haveYouTakenAspirin") {
          questionnaires.haveYouTakenAspirin = el.answer;
        }

        // if (el.answerType === "haveYouBittenByInsect") {
        //   questionnaires.haveYouBittenByInsect = el.answer;
        // }

        if (el.answerType === "haveYouHadMalaria") {
          questionnaires.haveYouHadMalaria = el.answer;
        }

        if (el.answerType === "vaccine") {
          questionnaires.vaccine = el.answer;
        }

        if (el.answerType === "dentalExtraction") {
          questionnaires.dentalExtraction = el.answer;
        }

        if (el.answerType === "continuousLowGradeFeverOrAnyDisease") {
          questionnaires.continuousLowGradeFeverOrAnyDisease = el.answer;
        }

        if (el.answerType === "tattooingOrEarPiercing") {
          questionnaires.tattooingOrEarPiercing = el.answer;
        }

        if (el.answerType === "surgeryOrCircumcision") {
          questionnaires.surgeryOrCircumcision = el.answer;
        }

        if (el.answerType === "endoscopy") {
          questionnaires.endoscopy = el.answer;
        }

        if (el.answerType === "bloodTransfusion") {
          questionnaires.bloodTransfusion = el.answer;
        }

        if (el.answerType === "heartDisease") {
          questionnaires.heartDisease = el.answer;
        }

        if (el.answerType === "kidneyDisease") {
          questionnaires.kidneyDisease = el.answer;
        }

        if (el.answerType === "cancer") {
          questionnaires.cancer = el.answer;
        }

        if (el.answerType === "diabetes") {
          questionnaires.diabetes = el.answer;
        }

        if (el.answerType === "tuberculosis") {
          questionnaires.tuberculosis = el.answer;
        }

        if (el.answerType === "abnormalBleedingTendency") {
          questionnaires.abnormalBleedingTendency = el.answer;
        }

        if (el.answerType === "asthma") {
          questionnaires.asthma = el.answer;
        }

        if (el.answerType === "hepatitisBOrC") {
          questionnaires.hepatitisBOrC = el.answer;
        }

        if (el.answerType === "HIVAIDS") {
          questionnaires.HIVAIDS = el.answer;
        }

        if (el.answerType === "gonorrhea") {
          questionnaires.gonorrhea = el.answer;
        }

        if (el.answerType === "neighborWithInfectious") {
          questionnaires.neighborWithInfectious = el.answer;
        }

        if (el.answerType === "epilepsy") {
          questionnaires.epilepsy = el.answer;
        }

        if (el.answerType === "repeatedDiarrhea") {
          questionnaires.repeatedDiarrhea = el.answer;
        }

        if (el.answerType === "weightLoss") {
          questionnaires.weightLoss = el.answer;
        }
        if (el.answerType === "swollenGlands") {
          questionnaires.swollenGlands = el.answer;
        }
        if (el.answerType === "syphillis") {
          questionnaires.syphillis = el.answer;
        }

        if (this.props?.donor?.gender === "female") {
          if (el.answerType === "areYouPregnant") {
            questionnaires.areYouPregnant = el.answer;
          }

          if (el.answerType === "doYouHaveChildUnder9Months") {
            questionnaires.doYouHaveChildUnder9Months = el.answer;
          }

          if (el.answerType === "haveYouHadAbortionInTheLastSixMonths") {
            questionnaires.haveYouHadAbortionInTheLastSixMonths = el.answer;
          }

          if (el.answerType === "doYouHaveChildUnder9Months") {
            questionnaires.doYouHaveChildUnder9Months = el.answer;
          }
          if (el.answerType === "haveYouHadAbortionInTheLastSixMonths") {
            questionnaires.haveYouHadAbortionInTheLastSixMonths = el.answer;
          }

          if (el.answerType === "areYouMenstrualPeriod") {
            questionnaires.areYouMenstrualPeriod = el.answer;
          }
        }
      });
    }

    this.setState({
      questionnaires,
      isEligible: info.isEligible ? "Yes" : "No",
    });
  }

  render() {
    console.log(this.state.questionnaires);
    return (
      <>
        <div className="card mb-3">
          <div className="card-body">
            <Input
              placeholder="Enter donor number"
              label="Donor Number"
              value={this.state.questionnaires.donorNumber}
              onChange={(e) => this.onChangeText("donorNumber", e)}
              rightButtonIcon={icons.search}
              rightButtonPressed={this.handleDonorSearch.bind(this)}
              profileData={this.state.donor}
              rightButtonSubmitting={this.state.isSearchingDonor}
            />
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <CenterReceiver
              {...this.state.questionnaires}
              onChangeText={this.onChangeText.bind(this)}
              error={this.state.error}
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <PersonalInformation
              handleCheck={this.handleCheck.bind(this)}
              onChangeText={this.onChangeText.bind(this)}
              error={this.state.error}
              {...this.state.questionnaires}
            />
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <HealthInformation
              handleCheck={this.handleCheck.bind(this)}
              onChangeText={this.onChangeText.bind(this)}
              error={this.state.error}
              {...this.state.questionnaires}
              gender={this.state.donor.gender}
            />
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <Acknowledgment
              handleCheck={this.handleCheck.bind(this)}
              onChangeText={this.onChangeText.bind(this)}
              error={this.state.error}
              {...this.state.questionnaires}
            />
          </div>
        </div>
        {this.state.user.role !== "donor" && (
          <div className="card mb-3">
            <div className="card-header">
              <p className="text-bold">
                {language[this.props.defaultLanguage].decision}
              </p>
            </div>
            <div className="card-body">
              <div className="d-flex gap-3">
                <CheckboxQuestionnaire
                  questionText={language[this.props.defaultLanguage].eligible}
                  answers={[
                    {
                      field: "isEligible",
                      value: "Yes",
                      onPress: () =>
                        this.handleDecisionCheck("isEligible", "Yes"),
                    },
                    {
                      field: "isEligible",
                      value: "No",
                      onPress: () =>
                        this.handleDecisionCheck("isEligible", "No"),
                    },
                  ]}
                  error={this.state.error.isEligible}
                  checked={this.state.isEligible}
                />
              </div>

              <div className="col-md-6">
                <Select
                  loadOptions={(e) => this.getAccounts(e)}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].selector}
                  value={this.state.selector}
                  onChange={(e) => this.setState({ selector: e })}
                  error={this.state.error.selector}
                  isLoading={this.state.isFetchingAccount}
                />
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <div className="d-flex gap-3">
              <Button
                text={language[this.props.defaultLanguage].submit}
                isSubmitting={this.state.isSubmitting}
                onPress={() => this.onSubmit()}
                disabled={!this.state.questionnaires.isAgree}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PreAssessmentRegistration);
